// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
ActiveStorage.start();

window.set_validation = function () {
  $("form.validation-form").validate({
    ignore: ".ignore, .select2-input",
    focusInvalid: true,
    rules: {
      "validation-email": {
        required: true,
        email: true,
      },
      // "lead[contact_email]": {
      //   required: true,
      //   email: true,
      // },
      // "lead[contact_phone]": {
      //   required: true,
      //   phoneUK: true,
      // },
      "validation-password": {
        required: true,
        minlength: 6,
        maxlength: 20,
      },
      "validation-password-confirmation": {
        required: true,
        minlength: 6,
        equalTo: 'input[name="validation-password"]',
      },
      "validation-required": {
        required: true,
      },
      "loan_application[address_post_code]": {
        required: false,
        postcodeUK: true,
      },
      // "lead[development_postcode]": {
      //   required: true,
      //   postcodeUK: true,
      // },
      "validation-url": {
        required: true,
        url: true,
      },
      "validation-select": {
        required: true,
      },
      "validation-multiselect": {
        required: true,
        minlength: 2,
      },
      "validation-select2": {
        required: true,
      },
      "validation-select2-multi": {
        required: true,
        minlength: 2,
      },
      "validation-text": {
        required: true,
      },
      "validation-file": {
        required: true,
      },
      "validation-radios": {
        required: true,
      },
      "validation-checkbox": {
        required: true,
      },
    },
    // Errors
    errorPlacement: function errorPlacement(error, element) {
      var $parent = $(element).parents(".error-placeholder");
      // Do not duplicate errors
      if ($parent.find(".jquery-validation-error").length) {
        return;
      }
      $parent.append(
        error.addClass(
          "jquery-validation-error small form-text invalid-feedback"
        )
      );
    },
    highlight: function (element) {
      var $el = $(element);
      var $parent = $el.parents(".error-placeholder");
      $el.addClass("is-invalid");
      // Select2 and Tagsinput
      if (
        $el.hasClass("select2-hidden-accessible") ||
        $el.attr("data-role") === "tagsinput"
      ) {
        $el.parent().addClass("is-invalid");
      }
    },
    unhighlight: function (element) {
      $(element)
        .parents(".error-placeholder")
        .find(".is-invalid")
        .removeClass("is-invalid");
    },
  });
};

window.setAlljs = function () {
  set_validation();
  setYesNoSections();
  setMenuCollapse();
  setSaveLaterAutoSave();
  commentsForm();
  // http://www.decorplanit.com/plugin/
  $(".auto-numeric").autoNumeric("init", { aPad: false });
  setStage1ContructionsPeriodTotal();
  setLeadLoanTotalsTotal();
  // setFileUploads();
  timeStampCss();
  setPlaidLinkBankAccountButton();
  $(function () {
    $('[data-toggle="popover"]').popover();
  });
};

document.addEventListener("DOMContentLoaded", function () {
  // Datatables Responsive
  $(".ht-datatable").DataTable({
    pageLength: 50,
    lengthChange: false,
  });
});

document.addEventListener("DOMContentLoaded", function () {
  // Initialize validation
  setAlljs();
  initMaps();
});

window.timeStampCss = function () {
  var elem = $(".time-stamp-text-holder");
  if (elem.hasClass("text-success")) {
    setTimeout(function () {
      $(".time-stamp-text-holder").removeClass("text-success");
    }, 3000);
  }
};

function setFileUploads() {
  $(document).on("change", ".ht-file-upload", function () {
    if ($("#save_for_later_submit_button")) {
      $("#save_for_later_submit_button").click();
    }
  });
}

function setMenuCollapse() {
  $("a.sidebar-toggle").click(function () {
    $.ajax({
      url: "/tooggle_collapsed_menu",
      type: "get",
    });
  });
}

function setYesNoSections() {
  $(".ht-yes-no-section").each(function () {
    var yesSection = $(this).find(".yes-no-section-yes-section");
    var noSection = $(this).find(".yes-no-section-no-section");

    if (!yesSection.is(":visible")) {
      hideAndDisableSection(yesSection);
    }

    if (!noSection.is(":visible")) {
      hideAndDisableSection(noSection);
    }

    $(this)
      .find("input.yes-no-option:radio")
      .on("click", function (e) {
        if (e.currentTarget.value === "true") {
          showAndEnableSection(yesSection);
          hideAndDisableSection(noSection);
        } else {
          showAndEnableSection(noSection);
          hideAndDisableSection(yesSection);
        }
      });
  });
}

function showAndEnableSection(section) {
  var requiredFields = $(section).find(".validation-required");
  requiredFields.attr("required", "required");
  section.show();
}

function hideAndDisableSection(section) {
  var requiredFields = $(section).find(".validation-required");
  requiredFields.removeAttr("required");
  section.hide();
}

function commentsForm() {
  $(".comments_form").on("input change", function () {
    $("#save_comment").attr("disabled", false);
  });
}

window.autoSaveId = 0;

function setSaveLaterAutoSave() {
  $("#save-later-left-hand-menu").on("click", function (e) {
    $("#save_for_later_submit_button").click();
  });

  $(".loan_application_form").on("input change", function () {
    $(".save_for_later_button_to_toggle").attr("disabled", false);
    clearTimeout(window.autoSaveId);
    window.autoSaveId = setTimeout(autoSaveForm, 30000);
  });
}

function autoSaveForm() {
  $("#auto_saving").val("true");
  $("#save_for_later_submit_button").click();
}

function setLeadLoanTotalsTotal() {
  $("#lead_gross_loan_amount").blur(function () {
    addLeadLoanTotals();
  });
  $("#lead_development_loan_amount").blur(function () {
    addLeadLoanTotals();
  });
}

function addLeadLoanTotals() {
  var lead_gross_loan_amount = parseFloat(
    $("#lead_gross_loan_amount").val().replace(/,/g, "")
  );
  var lead_development_loan_amount = parseFloat(
    $("#lead_development_loan_amount").val().replace(/,/g, "")
  );

  if (isNaN(lead_gross_loan_amount) || isNaN(lead_development_loan_amount)) {
    return;
  } else {
    $("#loan_amount").val(
      (lead_gross_loan_amount + lead_development_loan_amount).toLocaleString(
        "en-GB"
      )
    );
  }
}

function setStage1ContructionsPeriodTotal() {
  $("#loan_application_pre_construction_period").blur(function () {
    addContructionTotals();
  });
  $("#loan_application_construction_period").blur(function () {
    addContructionTotals();
  });
  $("#loan_application_sales_period").blur(function () {
    addContructionTotals();
  });
}

function addContructionTotals() {
  var pre_construction_period = parseInt(
    $("#loan_application_pre_construction_period").val()
  );
  var construction_period = parseInt(
    $("#loan_application_construction_period").val()
  );
  var sales_period = parseInt($("#loan_application_sales_period").val());
  if (
    isNaN(pre_construction_period) ||
    isNaN(construction_period) ||
    isNaN(sales_period)
  ) {
    return;
  } else {
    $("#loan_application_total_period").val(
      pre_construction_period + construction_period + sales_period
    );
  }
}

function initMaps() {
  $(".ht-google-map").each(function (index, mapElement) {
    const myLatLng = {
      lat: $(mapElement).data("latitude"),
      lng: $(mapElement).data("longitude"),
    };
    const map = new google.maps.Map(mapElement, {
      zoom: 14,
      center: myLatLng,
    });

    new google.maps.Marker({
      position: myLatLng,
      map,
      title: "Development at " + $(mapElement).data("postcode"),
    });
  });
}
const setPlaidLinkBankAccountButton = () => {
  $("#plaid-link-button").on("click", (event) => {
    event.preventDefault();

    const loanApplicationGuid = event.target.dataset.loanApplicationGuid;

    startBankAccountLinkFlow(loanApplicationGuid);
  });
};

const startBankAccountLinkFlow = async (loanApplicationGuid) => {
  const linkToken = await createLinkToken(loanApplicationGuid);

  const handler = createPlaidHandler(linkToken, loanApplicationGuid);
  handler.open();
};

const createLinkToken = async (loanApplicationGuid) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  const headers = { "X-CSRF-Token": csrfToken, Accept: "application/json" };

  const response = await fetch(
    `/loan_applications/${loanApplicationGuid}/create_link_token`,
    {
      method: "PUT",
      headers,
    }
  );

  const { link_token } = await response.json();

  return link_token;
};

const createPlaidHandler = (linkToken, loanApplicationGuid) => {
  // If Plaid is not available, throw an Error
  if (typeof window === "undefined" || !window.Plaid) {
    throw new Error("[Plaid] Not loaded");
  }

  return window.Plaid.create({
    token: linkToken,
    onSuccess: async (public_token, metadata) => {
      const csrfToken = document.querySelector(
        'meta[name="csrf-token"]'
      ).content;
      const headers = {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      const response = await fetch(
        `/loan_applications/${loanApplicationGuid}/exchange_public_token`,
        {
          method: "PUT",
          headers,
          body: JSON.stringify({
            public_token,
            metadata: {
              accounts: metadata.accounts,
              institution: metadata.institution,
              link_session_id: metadata.link_session_id,
            },
          }),
        }
      );

      if (response.ok) {
        window.location.replace(window.location.href);
      }
    },
    onExit: () => {
      console.log("[Plaid] User exited Link flow");
    },

    // required for OAuth; if not using OAuth, set to null or omit:
    // receivedRedirectUri: window.location.href,
  });
};

require("trix");
require("@rails/actiontext");
